import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getTenant } from '../../hooks/helpers';

const Logo = ({
  STATIC_PAGES_ENABLED,
  CUSTOM_MENUS_ENABLED,
  handleCustomMenu,
}) => {
  const [logoURL, setLogoURL] = useState('');
  const [errorCount, setErrorCount] = useState(0);

  const handleImageError = (e) => {
    setErrorCount((prevCount) => prevCount + 1);
    if (errorCount < 3) {
      e.target.src = process.env.REACT_APP_IBL_DEFAULT_LOGO_URL;
    } else {
      console.error('Image failed to load more than three times.');
    }
  };

  useEffect(() => {
    const tenant = getTenant();
    if (tenant && !STATIC_PAGES_ENABLED) {
      setLogoURL(
        `${process.env.REACT_APP_IBL_AXD_URL}/api/core/orgs/${getTenant()}/logo/`
      );
    } else {
      setLogoURL(process.env.REACT_APP_IBL_DEFAULT_LOGO_URL);
    }
    STATIC_PAGES_ENABLED && CUSTOM_MENUS_ENABLED && handleCustomMenu();
  }, [STATIC_PAGES_ENABLED, CUSTOM_MENUS_ENABLED, handleCustomMenu]);

  return (
    <Link className="skills-logo w-inline-block w--current" to="/">
      <img
        alt=""
        className="image"
        loading="lazy"
        onError={handleImageError}
        src={logoURL}
        style={{
          height: process.env.REACT_APP_IBL_LOGO_HEIGHT || '57px',
        }}
      />
    </Link>
  );
};

export default Logo;
