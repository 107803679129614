import { Outlet, useLocation } from 'react-router-dom';
import {
  AppDataContext,
  Copilot,
  useUserTimeTracking,
  useDashboard,
  api,
} from '@iblai/ibl-web-react-common';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import './DashboardView.css';
import { useContext, useEffect, useRef } from 'react';
import CopilotLauncher from '../../components/CopilotLauncher/CopilotLauncher';
import CopilotCloser from '../../components/CopilotCloser/CopilotCloser';
const SHOW_MENTOR = process.env.REACT_APP_IBL_ENABLE_MENTOR === 'true';

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const DashboardView = () => {
  const { appData, setAppData } = useContext(AppDataContext);
  useUserTimeTracking(api);
  const { userMetadataData, userMetadataDataLoaded } = useDashboard();
  const hasMounted = useRef(false);
  useEffect(() => {
    if (hasMounted.current) {
      setAppData({
        ...appData,
        userMetaData: userMetadataData,
        userMetaDataLoaded: userMetadataDataLoaded,
      });
    } else {
      hasMounted.current = true;
    }
  }, [userMetadataDataLoaded]);
  return (
    <span>
      <span className="af-view">
        <div className="body">
          <div className="full">
            <div
              className="main-content"
              style={{
                ...(SHOW_MENTOR ? {} : { paddingRight: 'unset' }),
                ...(inIframe()
                  ? { marginRight: '0px', paddingRight: '0px' }
                  : {}),
              }}
            >
              <div className="div-block-326">
                {!inIframe() && <Header></Header>}
                {/*{!inIframe() && <MobileHeader></MobileHeader>}*/}
                <Outlet />
                {!inIframe() && <Footer></Footer>}
              </div>
              {/*<CopilotLauncher />*/}
            </div>
            {!inIframe() && SHOW_MENTOR && <Copilot />}
            {/*<CopilotCloser />*/}
          </div>
          {/*<CopilotLauncher />*/}
        </div>
      </span>
    </span>
  );
};

export default DashboardView;
